import Badge from "@/components/shared/Badge"
import { ComicState } from "swagger/models/comic-state"

export const getStateOfCartoon = (state: ComicState, createdAt: Date) => {
  const createdAtDate = new Date(createdAt)
  const currentDate = new Date()
  const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000 // Days * hours * minutes * seconds * milliseconds

  const isNew = currentDate.getTime() - createdAtDate.getTime() < thirtyDaysInMilliseconds

  if (state === ComicState.PREPARATION) {
    return (
      <Badge className="absolute left-4 top-4" variant="blue" inverse>
        Připravujeme
      </Badge>
    )
  } else if (isNew) {
    return (
      <Badge className="absolute left-4 top-4" variant="green" inverse>
        Nový
      </Badge>
    )
  }
  return <></>
}
