import { searchComics } from "@/api/comics"
import { useQuery } from "@tanstack/react-query"
import { SearchPlayerComicRequest } from "swagger/models"

const useSearchComics = (body: SearchPlayerComicRequest, page?: number, size?: number, sort?: Array<string>) => {
  const { data: { data: comicsList } = {}, ...rest } = useQuery(
    ["comicsList", page, size, body, sort],
    () => searchComics(body, page, size, sort),
    {
      retry: 1,
      enabled: page !== null,
      refetchOnWindowFocus: "always",
    },
  )

  return {
    comicsList,
    ...rest,
  }
}

export default useSearchComics
