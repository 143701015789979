import { useTranslation } from "next-i18next"
import Image from "next/image"
import React, { useEffect, useState } from "react"
import { twMerge } from "tailwind-merge"

interface SearchInputProps {
  onSearch: (query: string) => void
  className?: string
  mainClassName?: string
  placeholder?: string
  variant?: "default" | "variant1"
  onClick?: () => void
  autofocus?: boolean
  onClickIcon?: () => void
  defaultValue?: string
}

const SearchInput: React.FC<SearchInputProps> = ({
  placeholder,
  mainClassName,
  className,
  onSearch,
  variant = "default",
  onClick,
  autofocus = false,
  onClickIcon,
  defaultValue,
}) => {
  const { t } = useTranslation("common")
  const [query, setQuery] = useState(defaultValue || "")

  const defaultPlaceholder = placeholder || t("searchCartoon")

  useEffect(() => {
    const resetQueryTimeout = setTimeout(() => {
      onSearch(query)
    }, 200)

    return () => clearTimeout(resetQueryTimeout)
  }, [onSearch, query])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setQuery(value)
  }

  const handleClearClick = () => {
    setQuery("")
  }

  return (
    <form className={twMerge("relative w-full", mainClassName)} onClick={onClick}>
      <input
        placeholder={defaultPlaceholder}
        className={twMerge(
          "h-12 w-full rounded-full border border-primary-50 border-opacity-10 bg-primary-500 pl-12 text-[16px] font-medium text-primary-50 placeholder:text-primary-300",
          className,
        )}
        type="text"
        value={query}
        onChange={handleInputChange}
        autoFocus={autofocus}
      />

      <div
        className={twMerge("absolute left-4 top-3", variant == "variant1" && query && "cursor-pointer")}
        onClick={variant == "variant1" && query ? onClickIcon : () => {}}
      >
        <Image
          src={variant == "variant1" && query ? "/assets/images/chevron-left.svg" : "/assets/images/search.svg"}
          alt={variant == "variant1" && query ? t("back") : t("searchCartoon")}
          width={24}
          height={24}
        />
      </div>
      {query.length > 0 && (
        <Image
          onClick={handleClearClick}
          src="/assets/images/x.svg"
          alt="Clear"
          width={24}
          height={24}
          className="absolute right-4 top-3 cursor-pointer"
        />
      )}
    </form>
  )
}

export default SearchInput
