import Image from "next/image"
import React from "react"

interface BadgeIconContent {
  crown?: boolean
  location?: boolean
  help?: boolean
}

const BadgeIconContent: React.FC<BadgeIconContent> = ({ crown, location, help }) => {
  return (
    <>
      <Image src={`/assets/images/crown${crown ? "-check" : ""}.svg`} width={24} height={24} alt={""} />
      <div className="mx-2 h-4 w-[1px] bg-primary-50 bg-opacity-16"></div>
      <Image src={`/assets/images/location-pin${location ? "-check" : ""}.svg`} width={24} height={24} alt={""} />
      <div className="mx-2 h-4 w-[1px] bg-primary-50 bg-opacity-16"></div>
      <Image src={`/assets/images/help-chat${help ? "-check" : ""}.svg`} width={24} height={24} alt={""} />
    </>
  )
}

export default BadgeIconContent
