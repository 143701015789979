import Typography from "@/components/shared/Typography"
import Image from "next/image"
import Link from "next/link"
import React from "react"
import { ComicState } from "swagger/models"

interface CardProps {
  title: string
  subtitle: string
  image: string
  link: string
  state?: ComicState
  children?: React.ReactNode
}

const Card = ({ title, subtitle, image, link, children, state }: CardProps) => {
  return (
    <Link
      href={link}
      className={`relative block max-w-full rounded-3xl bg-primary-500 p-2 pb-3 sm:w-full md:w-full ${
        state === ComicState.PREPARATION && "pointer-events-none cursor-not-allowed"
      }`}
    >
      {children}
      <Image className="h-[197px] w-full rounded-2xl object-cover" width={368} height={197} src={image} alt={title} />
      <Typography type="h3" className="ml-2 mt-[18px]">
        {title}
      </Typography>
      <Typography type="m" className="ml-2 mt-1  text-primary-300">
        {subtitle}
      </Typography>
    </Link>
  )
}

export default Card
