import Typography from "@/components/shared/Typography"
import { useTranslation } from "next-i18next"
import Image from "next/image"
import React from "react"

interface BannerProps {
  className?: string
}

const Banner: React.FC<BannerProps> = ({ className }) => {
  const { t } = useTranslation("common")

  return (
    <div
      className={`${className} -pt-20 relative rounded-3xl bg-primary-500 bg-[url(/assets/images/banner_bg_sm.png),_url(/assets/images/banner_noise_sm.png)] bg-cover p-6 md:bg-[url(/assets/images/banner_bg.png),_url(/assets/images/banner_noise.png)] md:px-12 md:py-10`}
    >
      <div className="relative mx-auto -mt-14 mb-8 md:absolute md:bottom-0 md:right-0 md:top-auto md:mb-0 md:translate-x-0 xl:right-20">
        <Image src="/assets/images/banner_mobile.png" width={413} height={323} alt="" className="hidden md:block" />
        <Image
          src="/assets/images/banner_mobile_sm.png"
          width={413}
          height={323}
          alt=""
          className="mx-auto md:hidden"
        />
      </div>
      <div className="md:max-w-[50%] md:pt-0">
        <Typography type="h2" className="mb-4">
          {t("banner.title")}
        </Typography>
        <Typography type="l" className="mb-10 text-primary-100">
          {t("banner.description")}
        </Typography>
        <div className="flex gap-4">
          <a href="https://apps.apple.com/cz/app/poznejme-%C4%8Desko/id6630388070?l=cs" target="_blank">
            <Image src="/assets/images/apple.svg" width={120} height={40} alt="Apple" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=cz.cleevio.poznejmecesko" target="_blank">
            <Image src="/assets/images/googlePlay.svg" width={135} height={40} alt="Google" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Banner
