import MainLayout from "@/components/layouts/MainLayout"
import HomePage from "@/components/pages/HomePage"

import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import { ReactElement } from "react"

const Page = () => <HomePage />

Page.getLayout = function getLayout(page: ReactElement) {
  return <MainLayout>{page}</MainLayout>
}

export async function getStaticProps({ locale }) {
  const translations = await serverSideTranslations(locale, ["common", "dashboard"])

  return {
    props: {
      ...translations,
    },
  }
}

export default Page
