import React from "react"
import { Icon } from "../Icons"

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const generatePageNumbers = () => {
    let pages: (number | string)[] = []
    if (totalPages <= 7) {
      pages = Array.from({ length: totalPages }, (_, i) => i + 1)
    } else {
      if (currentPage <= 4) {
        pages = [1, 2, 3, 4, 5, "...", totalPages]
      } else if (currentPage > totalPages - 4) {
        pages = [1, "...", totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages]
      } else {
        pages = [1, "...", currentPage - 1, currentPage, currentPage + 1, "...", totalPages]
      }
    }
    return pages
  }

  return (
    <div className="my-8 flex items-center justify-between text-primary-400 max-sm:justify-center">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="flex items-center gap-[2px] max-sm:hidden"
      >
        <Icon name="ArrowLeft" className="w-8" /> <span className="block max-sm:hidden"> Předchozí</span>
      </button>
      <div>
        {generatePageNumbers().map((page, index) => (
          <button
            key={index}
            onClick={() => typeof page === "number" && onPageChange(page)}
            disabled={page === "..."}
            className={`mx-1 px-2 py-1 ${page === currentPage ? "size-8 rounded-lg bg-secondary-400 text-white" : ""}`}
          >
            {page}
          </button>
        ))}
      </div>
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="flex items-center gap-[2px] max-sm:hidden"
      >
        Další
        <Icon name="ArrowRight" className="w-8" />
      </button>
    </div>
  )
}

export default Pagination
