import { axiosInstance } from "@/api/axiosInstance"
import { AxiosRequestConfig } from "axios"
import { SearchPlayerComicRequest } from "swagger/models"
import { PlayerComicApi } from "../../swagger/api"
import { Configuration } from "../../swagger/configuration"
import { getFirebaseIDToken } from "../api/auth/firebase"

const getAccessToken = async () => {
  const accessToken = await getFirebaseIDToken()
  return accessToken
}

const getApiConfig = () =>
  new Configuration({
    basePath: process.env.NEXT_PUBLIC_API_URL,
    //@ts-ignore
    accessToken: getAccessToken,
  })

const apiConfiguration = getApiConfig()

const COMICS_API = new PlayerComicApi(apiConfiguration, apiConfiguration.basePath, axiosInstance)

export const getComicsBySlug = async (comicId: string, options?: AxiosRequestConfig) =>
  COMICS_API.getPlayerComicBySlug(comicId, options)

export const getComicsById = async (comicId: string, options?: AxiosRequestConfig) =>
  COMICS_API.getPlayerComic(comicId, options)

export const searchComics = async (
  body: SearchPlayerComicRequest,
  page?: number,
  size?: number,
  sort?: Array<string>,
  options?: AxiosRequestConfig,
) => COMICS_API.searchComic(body, page, size, sort, options)

export const searchComicsRecomended = async (
  page?: number,
  size?: number,
  sort?: Array<string>,
  options?: AxiosRequestConfig,
) => COMICS_API.searchRecommendedComic(page, size, sort, options)
