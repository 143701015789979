import React from "react";

const CardSkeleton: React.FC = () => {
  return (
    <div className="max-w-full rounded-3xl bg-primary-500 p-2 pb-3 sm:w-full md:w-full">
      <div className="placeholder w-full h-0 aspect-w-16 aspect-h-9 bg-gray-300 animate-pulse rounded-2xl"></div>
      <div className="placeholder w-full h-[200px] bg-gray-300 animate-pulse mb-2 rounded-3xl" ></div>

      <div className="mx-2 mt-[18px]">
        <div className="placeholder w-2/3 h-4 bg-gray-300 animate-pulse mb-2"></div>
        <div className="placeholder w-1/2 h-3 bg-gray-300 animate-pulse"></div>
      </div>
    </div>
  );
};

export default CardSkeleton;
